import useSWR from 'swr'

import { defaultPreferences } from 'core/remodel/types/user'
import { fetchPreferences, userQuery } from '@/api/AccountService'
import { cn } from '@/utils/classnames'
import { formatNumber } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/base'

type Stat = {
  label: string
  value: number
  unit?: string
}

interface SummaryProps {
  stats: Stat[]
  className?: string
}

export default function Summary({ stats, className }: SummaryProps) {
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))

  return (
    <div className={cn('flex flex-col rounded bg-white pl-4 text-text shadow lg:flex-row', className)}>
      {stats.map(({ label, value, unit }, index) => {
        const isShortening = value >= 1e9
        const displayValue = [unit, formatNumber(value, preferences.numberFormat, { digits: 0 })]
          .filter(Boolean)
          .join(' ')
        const tooltipValue = [unit, formatNumber(value, preferences.numberFormat, { digits: 2, isShortening: false })]
          .filter(Boolean)
          .join(' ')
        return (
          <div key={index} className={'my-4 flex-1 space-y-2 border-l border-grey px-4'}>
            <h6 className={'uppercase'}>{label}</h6>
            <Tooltip>
              <TooltipTrigger asChild={true}>
                <span className={cn('text-2xl font-bold', value < 0 && 'text-error')}>{displayValue}</span>
              </TooltipTrigger>
              {isShortening && (
                <TooltipContent side={'top'} sideOffset={8}>
                  <span className={'text-xs font-medium text-white'}>{tooltipValue}</span>
                </TooltipContent>
              )}
            </Tooltip>
          </div>
        )
      })}
    </div>
  )
}
