import { useEffect, useState } from 'react'
import Image from 'next/image'

import { useAuthStore } from '@/store/authStore'

interface EncryptedImageProps {
  className?: string
  src?: string
  base64IV?: string
  alt: string
  fill?: boolean
  placeholder?: string
}

/**
 * Renders an encrypted image that is fetched from a URL, decrypted, and displayed.
 *
 * @param className - The CSS class name for the image container.
 * @param src - The URL of the encrypted image.
 * @param base64IV - The base64-encoded IV salt for encrypting/decrypting the image.
 * @param alt - The alternative text for the image.
 * @param fill - Whether the image should fill its container.
 * @param placeholder - (NOT encrypted) The URL of a placeholder image to display while the encrypted image is being fetched and decrypted.
 * @returns The rendered encrypted image component.
 */
export function EncryptedImage({ className, src, base64IV, alt, fill, placeholder }: EncryptedImageProps) {
  const [decryptedUrl, setDecryptedUrl] = useState<string | null>(null)
  const database = useAuthStore((state) => state.database)

  useEffect(() => {
    if (!src || !base64IV) return

    const decryptImage = async () => {
      try {
        const resp = await fetch(src)
        const encrypted = await resp.arrayBuffer()
        const iv = database!.Encryption.current.convertBase64ToIVSalt(base64IV)
        const decrypted = await database!.Encryption.current.decryptBytes(encrypted, iv)
        // TODO should use original content-type?
        const blob = new Blob([decrypted], { type: 'image/jpeg' })
        const url = URL.createObjectURL(blob)
        setDecryptedUrl(url)
      } catch (err) {
        console.error(`Failed to fetch and decrypt image: ${err}`)
      }
    }

    if (!decryptedUrl) decryptImage()

    return () => {
      if (decryptedUrl) URL.revokeObjectURL(decryptedUrl)
    }
  }, [database, decryptedUrl, base64IV, src])

  const url = src ? decryptedUrl : placeholder

  return <>{url && <Image className={className} src={url} alt={alt} fill={fill} draggable={false} />}</>
}
